import React, { memo } from "react";
import PropTypes from "prop-types";
import { BooleanInput } from "react-admin";
import {
  Button,
  Modal,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import styles from "./styles";

const EditPaymentProvider = memo((props) => (
  <Modal
    open={props.isOpenModal}
    onClose={props.handleCloseModal}
    style={styles.modalContainer}
  >
    <Card style={styles.cardContainer}>
      <CardContent>
        <Typography
          variant='h4'
          gutterBottom
          style={styles.titleWhite}
          data-testid='Title-proveedor'>
          Detalles del proveedor
        </Typography>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          style={styles.divisorTop}
        >
          <Grid item style={styles.containerField}>
            <BooleanInput
              label={"Activo"}
              source="is_active"
              value={props.itemUpdate.is_active}
              onChange={(e) => props.handleOnchange(e.target.name, e.target.checked)}
              defaultValue={props.itemUpdate.is_active}
            />
            <BooleanInput
              label={"Validación de balance (Autopay)"}
              source="autopay_balance_validation"
              value={props.itemUpdate.autopay_balance_validation}
              onChange={(e) => props.handleOnchange(e.target.name, e.target.checked)}
              defaultValue={props.itemUpdate.autopay_balance_validation}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        style={styles.divisorTop}
      >
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleCloseModal}
            data-testid='button-Cerrar'
            style={styles.buttonClose}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleUpdateProvider}
            style={{ backgroundColor: '#33F5A5' }}
            data-testid='button-Actualizar'
            color="primary"
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
    </Card>
  </Modal>
));

EditPaymentProvider.propTypes = {
  handleCloseModal: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  isOpenModal: PropTypes.bool,
  itemUpdate: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool,
    autopay_balance_validation: PropTypes.bool,
  }),
};

EditPaymentProvider.defaultProps = {
  handleCloseModal: () => { },
  handleOnchange: () => { },
  handleUpdateProvider: () => { },
  setImage_design: () => { },
  isOpenModal: false,
  itemUpdate: {
    id: 1,
    is_active: false,
    autopay_balance_validation: false 
  },
};

export default EditPaymentProvider;

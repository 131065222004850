import React from "react";
import Swal from "sweetalert2";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  FunctionField,
} from "react-admin";
import { SegmentFilter } from "../Filters";
import Button from "@material-ui/core/Button";
import Rest from "../../../Providers/Rest";
import useAlerts from "../../../hooks/useAlerts"
import { FIELDS_EXPORTS_FILES } from "../../utils";
import useExportFiles from "../../../hooks/useExportFiles";

const SegmentsList = (props) => {

  const { history } = props;
  const { errorMessage } = useAlerts();
  const { exportFileSegmentos } = useExportFiles();

  const Cabecera = () => {
    return (
      <div style={{ backgroundColor: "#595c5d" }}>
        <Button size='small' onClick={() => history.push("/coupons/create")}>
          Nuevo Cupon
        </Button>
        <Button size='small' onClick={() => history.push("/coupons")}>
          Cupones
        </Button>
        <Button size='small' onClick={() => history.push("/segments")}>
          Segmentos
        </Button>
      </div>
    );
  };

  const send = async (e) => {
    try {
      const response = await Rest("DELETE", "segments", e);

      if (response.data === null)
        Swal.fire("Eliminado!", "El segmento ha sido eliminado.");

      history.push("/segments");
    } catch (e) {
      errorMessage(e.data.error.message)
      return
    }
  };

  const deleteSegment = (e) => {
    Swal.fire({
      title: "Estas seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        send(e);
      }
    });
  };

  let ButtonDelete = (propsRecord) => {
    const {
      record: { id },
    } = propsRecord;
    return (
      <>
        <button
          name={id}
          style={{
            backgroundColor: "#00d7f5",
            padding: 8,
            borderRadius: 10,
            borderColor: "none",
          }}
          type='button'
          onClick={(e) => deleteSegment(id)}
        >
          <div style={{ color: "#000000" }}>Eliminar</div>
        </button>
      </>
    );
  };

  const exporter = posts => {
    exportFileSegmentos(posts, FIELDS_EXPORTS_FILES.SEGMENTS)
  }

  return (
    <>
      <Cabecera />
      <List
        {...props}
        title={"Cupones"}
        filters={<SegmentFilter />}
        bulkActionButtons={false}
        exporter={exporter}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"id"} label={"Id"} />
          <TextField source={"attributes.name"} label={"Nombre"} />
          <FunctionField
            label={"Acciones"}
            render={(record) => (
              <a
                href={record.attributes.document_file_url}
                target='_blank'
                className='clickable'
                rel="noopener noreferrer"
              >
                Descargar
              </a>
            )}
          />
          ;
          <ShowButton basePath='/segments' label='' />
          <EditButton basePath='/segments' />
          <ButtonDelete />
        </Datagrid>
      </List>
    </>
  );
};

export default SegmentsList;
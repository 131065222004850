import React from "react";
import { Filter, TextInput } from "react-admin";

import ButtonClearFilters from "../../../../../Components/ButtonClearFilters";

const CountriesFilter = (props) => {
  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput label={"Nombre"} source={"country_name"} alwaysOn />
      <TextInput label={"Código ISO"} source={"iso_code"} alwaysOn />
    </Filter>
  );
};

export default CountriesFilter;

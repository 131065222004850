import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Grid } from "@material-ui/core";

import {
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  FourthColumn,
  ModalManualWithdrawal,
  ModalAutomaticWithdrawal,
  ModalRejectWithdrawal,
  AnimatedVita,
  ErrorAlert
} from "./components";
import useAlerts from "../../../../hooks/useAlerts";
import useReset from "../../../../hooks/useReset";
import TransactionProvider from "../../../../Providers/Transactions";

const RenderInfo = (props) => {
  const {
    record,
    isFetch,
    isError,
    setError,
    error,
    setId,
    hash,
    score,
    automaticWithdrawal,
    completeWithdrawal,
    rejectWithdrawal,
    calculateScore,
    changeHash
  } = props;
  const {
    id,
    attributes: {
      created_at,
      amount,
      to_address,
      description,
      total,
      total_fee,
      network,
      currency,
      locking_status,
      payment_processors,
      sender_user,
      active_alerts,
      alert_count,
    },
  } = record;
  const [isAutomaticWithdrawal, setAutomaticWithdrawal] = useState(false);
  const [isCompleteWitdrawal, setCompleteWithdrawal] = useState(false);
  const [isRejectWithdrawal, setRejectWithdrawal] = useState(false);

  const {
    showLoading,
    hiddenLoading,
    showSelect,
    successMessagePayment,
    errorMessage,
    showModal,
  } = useAlerts();
  const { reloadPage } = useReset();

  const onPayoutProviders = async () => {
    try {
      let processors = { ...payment_processors };
      const defaultProcessor = processors["default"];
      delete processors["default"];

      const params = {
        text: 'Por favor ingrese el procesador de pago',
        inputPlaceholder: 'Seleccione el procesador de pago',
        inputValue: defaultProcessor,
        inputOptions: processors,
      };

      const { isConfirmed = false, value = '' } = await showSelect(params);

      if (isConfirmed && value) {
        const params = {
          text: `Vamos a realizar el pago con ${value}`,
          title: `Pago automático con ${value.toUpperCase()}`
        };

        const { isConfirmed = false } = await showModal(params);

        if (isConfirmed) {
          showLoading();
          await TransactionProvider.acceptedWithdawal(id, value);
          successMessagePayment(value);
          reloadPage();
        }
      }
    } catch (error) {
      errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurriedo un error');
    }

    hiddenLoading();
  };

  setId(id);

  return (
    <Grid item xs={12} container spacing={24}>
      <Grid item xs={4}>
        <FirstColumn
          id={id}
          total={total}
          total_fee={total_fee}
          locking_status={locking_status}
          created_at={created_at}
          amount={amount}
          currency={currency}
          to_address={to_address}
          sender_user={sender_user}
          active_alerts={active_alerts}
          alert_count={alert_count}
        />
      </Grid>
      <Grid item xs={2}>
        <SecondColumn
          id={id}
          total={total}
          total_fee={total_fee}
          description={description}
          network={network}
          locking_status={locking_status}
        />
      </Grid>
      <Grid item xs={2}>
        <ThirdColumn
          score={score}
          record={record}
        />
      </Grid>
      <Grid item xs={4}>
        <FourthColumn
          id={id}
          currency={currency}
          setAutomaticWithdrawal={setAutomaticWithdrawal}
          setCompleteWithdrawal={setCompleteWithdrawal}
          setRejectWithdrawal={setRejectWithdrawal}
          calculateScore={calculateScore}
          locking_status={locking_status}
          to_address={to_address}
          network={network}
          isFetch={isFetch}
          payment_processors={payment_processors}
          onPayoutProviders={onPayoutProviders}
        />
      </Grid>
      {isAutomaticWithdrawal && (
        <>
          {isFetch ? (
            <AnimatedVita isFetch={isFetch} />
          ) : isError ? (
            <ErrorAlert
              error={error}
              setError={setError}
              setAction={setAutomaticWithdrawal}
              showError={true}
            />
          ) : (
            <ModalAutomaticWithdrawal
              isAutomaticWithdrawal={isAutomaticWithdrawal}
              setAutomaticWithdrawal={setAutomaticWithdrawal}
              automaticWithdrawal={automaticWithdrawal}
              payment_processor={payment_processors['default']}
              payment_processor_key={payment_processors['default']}
            />
          )}
        </>
      )}
      {isCompleteWitdrawal && (
        <>
          {isFetch ? (
            <AnimatedVita isFetch={isFetch} />
          ) : isError ? (
            <ErrorAlert
              error={error}
              setError={setError}
              setAction={setCompleteWithdrawal}
              showError={true}
            />
          ) : (
            <ModalManualWithdrawal
              isCompleteWitdrawal={isCompleteWitdrawal}
              setCompleteWithdrawal={setCompleteWithdrawal}
              hash={hash}
              changeHash={changeHash}
              completeWithdrawal={completeWithdrawal}
            />
          )}
        </>
      )}
      {isRejectWithdrawal && (
        <>
          {isFetch ? (
            <AnimatedVita isFetch={isFetch} />
          ) : isError ? (
            <ErrorAlert
              error={error}
              setError={setError}
              setAction={setRejectWithdrawal}
              showError={true}
            />
          ) : (
            <ModalRejectWithdrawal
              isRejectWithdrawal={isRejectWithdrawal}
              setRejectWithdrawal={setRejectWithdrawal}
              rejectWithdrawal={rejectWithdrawal}
            />
          )}
        </>
      )}
    </Grid>
  );
};

RenderInfo.defaultProps = {
  record: {
    id: 0,
    attributes: {
      created_at: new Date('2000-03-15T16:23:21.666Z'),
      amount: 0,
      total: 0,
      currency: 'usdc',
      locking_status: 'unlocked',
      sender_user: {
        data: {
          attributes: {
            first_name: '',
            last_name: '',
            email: ''
          },
        },
      },
    }
  },
  hash: '',
  score: {
    risk: 0,
    owner: 'Unknown',
    message: '',
  },
  isFetch: false,
  isError: false,
  setError: () => { },
  error: '',
  setId: () => { },
  automaticWithdrawal: () => { },
  completeWithdrawal: () => { }
};

RenderInfo.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    attributes: PropTypes.shape({
      created_at: PropTypes.instanceOf(Date).isRequired,
      amount: PropTypes.number.isRequired,
      to_address: PropTypes.string,
      description: PropTypes.string,
      total: PropTypes.number.isRequired,
      total_fee: PropTypes.number,
      network: PropTypes.string,
      currency: PropTypes.string.isRequired,
      locking_status: PropTypes.string.isRequired,
      sender_user: PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired
          }).isRequired,
        }).isRequired,
      }).isRequired,
    })
  }).isRequired,
  isFetch: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  score: PropTypes.shape({
    risk: PropTypes.number.isRequired,
    owner: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  automaticWithdrawal: PropTypes.func.isRequired,
  completeWithdrawal: PropTypes.func.isRequired,
  rejectWithdrawal: PropTypes.func,
  changeHash: PropTypes.func
};

export default RenderInfo;

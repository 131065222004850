import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { translateDocumentType } from '../../../../../../../Utils';

const UltimateBusinessOwners = (props) => {
  const { ultimate_business_owners } = props;

  return (
    <div style={{ margin: '10px 0px 40px' }}>
      <Grid item xs={6}>
        <Typography variant='h4' gutterBottom style={{ color: '#ffffff' }}>
          Beneficiarios Finales
        </Typography>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre completo</TableCell>
            <TableCell>Porcentaje</TableCell>
            <TableCell>Fecha de inicio en la empresa</TableCell>
            <TableCell>Documento</TableCell>
            <TableCell>Residencia</TableCell>
            <TableCell>¿Es gerente o director?</TableCell>
            <TableCell>
              ¿Tiene autorización para actuar en nombre de la empresa?
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ultimate_business_owners.map((item) => {
            const documentType = translateDocumentType(
              item?.document_type
            );

            return (
              <TableRow key={item.id}>
                <TableCell>
                  {item.first_name} {item.last_name}
                </TableCell>
                <TableCell>{item.ownership_percentage} %</TableCell>
                <TableCell>{item.start_date}</TableCell>
                <TableCell>{documentType}: {item.document_number}</TableCell>
                <TableCell>
                  {item.address}, {item.residence_city_name},{' '}
                  {item.residence_state_name}, {item.residence_country_name}
                </TableCell>
                <TableCell>{item.is_signer}</TableCell>
                <TableCell>{item.has_control}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UltimateBusinessOwners;

import React from 'react';
import { Filter, SelectInput, TextInput, DateInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import { ALERT_STATUS, ALERT_TYPES, ALERT_TABLE } from '../../../Utils';

import ButtonClearFilters from '../../../Components/ButtonClearFilters';

const AlertsFilter = (props) => {
  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
        alwaysOn
        label={'Id'}
        source={'id'}
      />
      <SelectInput
        alwaysOn
        label={'Estado'}
        source={'status'}
        choices={ALERT_STATUS}
      />
      <Grid
        label="Fecha"
        source={'alerts_dates'}
        alwaysOn
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="alerts_dates.from_date"           
          />
          <DateInput
            label={'Hasta'}
            source="alerts_dates.to_date"            
          />
        </Grid>
      </Grid>
      <SelectInput
        label={'Tipo de alerta'}
        source={'alert_type'}
        choices={ALERT_TYPES}
      />
      <SelectInput
        label={'Alerta para'}
        source={'alertable_type'}
        choices={ALERT_TABLE}
      />
      <TextInput
        label={'Id de la operación'}
        source={'alertable_id'}
      />
    </Filter>
  );
};

export default AlertsFilter;
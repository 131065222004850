import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Switch, FormControlLabel, CircularProgress, Typography, Card, CardContent } from "@material-ui/core";
import Swal from 'sweetalert2';

import { useReset, useAccessRole } from "../../../../../../hooks";
import styles from "../styles";
import TransactionProvider from "../../../../../../Providers/Transactions";
import ModalComponent from '../../../../../../Components/ModalComponent';
import CreateComment from '../../../../../../Components/comments/CreateComment';
import { FormDataConsumer, SimpleForm } from 'react-admin';

const FourthColumn = (props) => {
  const {
    id,
    currency,
    locking_status,
    setAutomaticWithdrawal,
    setCompleteWithdrawal,
    setRejectWithdrawal,
    calculateScore,
    to_address,
    network,
    isFetch,
    payment_processors,
    onPayoutProviders,
  } = props;

  const { resetList } = useReset();
  const { isAccess } = useAccessRole();
  const [isShowModalComment, setIsShowModalComment] = useState(false);
  const [commentTags, setCommentTags] = useState([]);
  const [isClickOnLock, setIsClickOnLock] = useState(false);

  const handleChangeLockedStatus = async () => {
    Swal.showLoading();
    const new_locking_status = locking_status === 'unlocked' ? 'locked_by_standard_flow' : 'unlocked';

    try {
      await TransactionProvider.updateLockedStatus(id, { locking_status: new_locking_status });

      resetList();
      Swal.close();
    } catch (error) {
      Swal.close();
      Swal.fire(
        'Lo sentimos, algo no fue bien',
        'No fue posible cambiar el estado de bloqueo de este registro.',
        'error',
      )
    }
  }

  return (
    <Grid style={{ padding: '7%', textAlign: "center" }} >
      {
        isAccess('edit_transaction_locked_status') && (<FormControlLabel
          control={
            <Switch checked={locking_status !== 'unlocked'} onChange={() => {
              setIsClickOnLock(true);
              setCommentTags(locking_status !== 'unlocked' ? ['transaction_unlock'] : ['transaction_lock']);
              setIsShowModalComment(true);
            }} />
          }
          label={locking_status !== 'unlocked' ? 'Desbloquear' : 'Bloquear'}
        />)
      }
      {
        isAccess('show_crypto_score') && (
          <Grid>
            {((to_address && network) || currency === 'btc') && (
              <Button
                onClick={calculateScore}
                style={locking_status !== 'unlocked' || isFetch ? { ...styles.buttonDisabled, fontSize: 12, width: "50%" } : { ...styles.buttonYellow, fontSize: 12, width: "50%", marginBottom: 5 }}
                disabled={locking_status !== 'unlocked' || isFetch}
              >
                {
                  isFetch ? (
                    <CircularProgress size={14} style={{ 'color': 'white' }} />
                  ) : (
                    'SCORE'
                  )
                }
              </Button>
            )}
          </Grid>
        )
      }
      {
        isAccess('crypto_complete_withdrawal') &&
        <Grid>
          <Button
            onClick={() => setCompleteWithdrawal(true)}
            style={locking_status !== 'unlocked' ? { ...styles.buttonDisabled, fontSize: 12, width: "50%" } : { ...styles.buttonPurple, fontSize: 12, width: "50%" }}
            disabled={locking_status !== 'unlocked'}
          >
            MARCAR COMPLETADA
          </Button>
        </Grid>
      }
      {
        isAccess('crypto_automatic_withdrawal') &&
        <Grid>
          <Button
            onClick={() => setAutomaticWithdrawal(true)}
            style={locking_status !== 'unlocked' ? { ...styles.buttonDisabled, fontSize: 12, width: "50%" } : {
              ...styles.buttonBlue,
              marginTop: 5,
              marginBottom: 5,
              fontSize: 12,
              width: "50%"
            }}
            disabled={locking_status !== 'unlocked'}
          >
            { payment_processors["default"] ? `Realizar pago con ${payment_processors["default"]}` : 'PAGO AUTOMÁTICO' }
          </Button>
        </Grid>
      }
      {
        isAccess('crypto_automatic_withdrawal') &&
        <Grid>
          <Button
            onClick={onPayoutProviders}
            style={locking_status !== 'unlocked' ? { ...styles.buttonDisabled, fontSize: 12, width: "50%" } : {
              ...styles.buttonBlue,
              marginTop: 5,
              marginBottom: 5,
              fontSize: 12,
              width: "50%"
            }}
            disabled={locking_status !== 'unlocked'}
          >
            REALIZAR PAGO CON OTROS PROVEEDORES
          </Button>
        </Grid>
      }
      {
        (isAccess('crypto_reject_withdrawal') && currency.toLowerCase() !== 'btc') &&
        <Grid>
          <Button
            onClick={() => setRejectWithdrawal(true)}
            style={locking_status !== 'unlocked' ? { ...styles.buttonDisabled, fontSize: 12, width: "50%" } : { ...styles.buttonRed, fontSize: 12, width: "50%" }}
            disabled={locking_status !== 'unlocked'}
          >
            RECHAZAR
          </Button>
        </Grid>
      }
      {
        (isAccess('create_transaction_comments')) && (
        <Grid>
          <Button
            onClick={() => {
              setIsClickOnLock(false);
              setCommentTags([]);
              setIsShowModalComment(true);
            }}
            style={{ ...styles.buttonOrange, fontSize: 12, width: "50%" }}
          >
            AGREGAR COMENTARIO
          </Button>
        </Grid>
        )
      }
      {
        isShowModalComment && (
            <ModalComponent
                open={isShowModalComment}
                onClose={()=>{setIsShowModalComment(false)}}
                marginTop={100}
                backdropStyle={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}
            >
                <Card>
                    <Typography
                    variant='headline'
                    align='center'
                    style={{
                        fontWeight: "bold",
                        paddingTop: 30,
                    }}
                    >
                        Agregar comentario
                    </Typography>
                    <CardContent style={{paddingLeft: 100}}>
                        <SimpleForm {...props} toolbar={null}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (
                                        <CreateComment
                                            {...formData}
                                            {...rest}
                                            id={props.id}
                                            onClose={()=>(isClickOnLock && handleChangeLockedStatus())}
                                            service={TransactionProvider.postTransactionComments}
                                            serviceExtraArgs={{ 'tags': commentTags }}
                                        />
                                    )
                                }
                            </FormDataConsumer>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </ModalComponent>
        )
      }
    </Grid>
  );
}

FourthColumn.defaultProps = {
  id: 0,
  currency: '',
  setAutomaticWithdrawal: () => { },
  setCompleteWithdrawal: () => { },
  setRejectWithdrawal: () => { },
  calculateScore: () => { },
  onPayoutProviders: () => {},
  locking_status: 'unlocked',
  isFetch: false,
  payment_processors: {
    default: '',
  },
};

FourthColumn.propTypes = {
  id: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  setAutomaticWithdrawal: PropTypes.func.isRequired,
  setCompleteWithdrawal: PropTypes.func.isRequired,
  setRejectWithdrawal: PropTypes.func.isRequired,
  calculateScore: PropTypes.func.isRequired,
  onPayoutProviders: PropTypes.func,
  locking_status: PropTypes.string.isRequired,
  to_address: PropTypes.string,
  network: PropTypes.string,
  isFetch: PropTypes.bool.isRequired,
  payment_processors: PropTypes.shape({
    default: PropTypes.string,
  }),
};

export default FourthColumn;
import React                              from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';

import { payments_types } from '../../../../Config/constants';
import ButtonClearFilters from '../../../../Components/ButtonClearFilters';

const Filters = (props) => {
  return(
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
          label={'Id'}
          source={'id'}
          alwaysOn
      />
      <TextInput
          label={'Retiro'}
          source={'transaction_id'}
          alwaysOn
      />
      <SelectInput
          label={'Tipo de pago'}
          source={'payment_type'}
          choices={payments_types}
      />
    </Filter>
)};

export default Filters;

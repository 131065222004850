import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import CardAccountEdit from "../../../../CardAccounts/CardAccountEdit";
import UsdAccountModal from "../UsdAccountModal";
import useUsdAccount from "../../../../../hooks/useUsdAccout";

moment.locale("es");

const UsdAccountPanel = (props) => {
  const {
    customLoadFormService,
    customUpdateFormService,
    rejectUsdAccount,
    retryUsdAccountCreation,
    handleAccountStatusChange,
    isRetryDisabled,
    isRejectDisabled,
    openModal,
    handleCloseModal,
    handleChangeRejectionReason,
    rejectionReason,
    handleRejectConfirmed,
    isRetryVirtualAccountButtonDisabled,
    retryVirtualAccountCreation,
    handleAccountVirtualStatusChange
  } = useUsdAccount(props);

  return (
    <>
      <CardAccountEdit
        id={props.id}
        loadFormService={customLoadFormService}
        updateFormService={customUpdateFormService}
        formType='bridge'
        redirectUrl={`/usuarios/${props.id}/13`}
        onAccountStatusChange={handleAccountStatusChange}
        onVirtualAccountStatusChange={handleAccountVirtualStatusChange}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: 20,
        }}
      >
        <Button
          color='primary'
          style={{
            backgroundColor: isRejectDisabled ? "#cccccc" : "#f44336",
          }}
          onClick={rejectUsdAccount}
          disabled={isRejectDisabled}
        >
          Rechazar cuenta
        </Button>
        <Button
          color='primary'
          style={{
            backgroundColor: isRetryDisabled ? "#cccccc" : "#27960b",
          }}
          onClick={retryUsdAccountCreation}
          disabled={isRetryDisabled}
        >
          Reintentar creación de cuenta
        </Button>
        <Button
          color='primary'
          style={{
            backgroundColor: isRetryVirtualAccountButtonDisabled ? "#cccccc" : "#00B7FF",
          }}
          onClick={retryVirtualAccountCreation}
          disabled={isRetryVirtualAccountButtonDisabled}
        >
          Reintentar creación de la cuenta virtual
        </Button>
      </div>
      <UsdAccountModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setRejectionReason={handleChangeRejectionReason}
        rejectionReason={rejectionReason}
        handleRejectConfirmed={handleRejectConfirmed}
      />
    </>
  );
};

UsdAccountPanel.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UsdAccountPanel;

import React, { useState } from "react";
import { TextField, Button, MenuItem, LinearProgress } from '@material-ui/core';
import { CountriesProvider } from '../../../../../../Providers';
import styles from "../styles";
import { TO_NUMBER, toMoney } from "../../../../../../Helpers/MoneyHelper";
import { Col, Row } from "react-grid-system";
import PropTypes from 'prop-types';
import useAlerts from "../../../../../../hooks/useAlerts";

const AutomaticPaymentCryptoRouteItem = (props) => {
    const {
        id: automatic_payment_route_id,
        country_id,
        network,
        currency,
        max_transaction_limit_amount,
        payment_processor,
        currencies,
        networks,
        refreshAutomaticPaymentRoutes,
        setRefreshAutomaticPaymentRoutes
    } = props;

    const [networkId, setNetworkId] = useState(network?.id?.toString());
    const [currencyId, setCurrencyId] = useState(currency?.id?.toString());
    const [maxTransactionLimitAmount, setMaxTransactionLimitAmount] = useState(max_transaction_limit_amount);
    const [paymentProcessor, setPaymentProcessor] = useState(payment_processor);
    const [isLoading, setIsLoading] = useState(false);
    const { errorMessage } = useAlerts();

    const createRoute = async (countryId, payload) => {
        setIsLoading(true);
        try {
            await CountriesProvider.createAutomaticPaymentCryptoRoute(countryId, payload);
            setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorMessage(`Error creating the route: ${error.data?.message}`);
        }
    };

    const updateRoute = async (countryId, automaticPaymentRouteId, payload) => {
        setIsLoading(true);
        try {
            await CountriesProvider.updateAutomaticPaymentCryptoRoute(countryId, automaticPaymentRouteId, payload);
            setIsLoading(false);
            setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            errorMessage(`Error updating the route: ${error.data?.message}`);
        }
    };

    const deleteRoute = async (countryId, automaticPaymentRouteId) => {
        setIsLoading(true);
        try {
            await CountriesProvider.deleteAutomaticPaymentCryptoRoute(countryId, automaticPaymentRouteId);
            setIsLoading(false);
            setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
        } catch (error) {
            setIsLoading(false);
            errorMessage(`Error deleting the route: ${error.data?.message}`);
        }
    };

    const saveData = async () => {
        const payload = {
            max_transaction_limit_amount: maxTransactionLimitAmount,
            network_id: networkId,
            currency_id: currencyId,
            payment_processor: paymentProcessor
        }

        if (automatic_payment_route_id) {
            await updateRoute(country_id, automatic_payment_route_id, payload);
        } else {
            await createRoute(country_id, payload);
        }
    };

    return <>
        <Row container style={styles.itemContainer}>
            <Col item>
                <TextField
                    label={'Red'}
                    value={networkId || 0}
                    onChange={(e) => setNetworkId(e.target.value)}
                    select
                    style={styles.fieldContainer}
                >
                    {
                        networks.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {`${item.key_name} - ${item.name}`}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>
            </Col>
            <Col item>
                <TextField
                    label={'Moneda'}
                    value={currencyId || 0}
                    onChange={(e) => setCurrencyId(e.target.value)}
                    select
                    style={styles.fieldContainer}
                >
                    {
                        currencies.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {`${item.iso_code} - ${item.plural_name}`}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>
            </Col>
            <Col item>
                <TextField
                    label={'Monto máximo por transacción (USD)'}
                    value={toMoney(maxTransactionLimitAmount, 'USD')}
                    onChange={(e) => setMaxTransactionLimitAmount(TO_NUMBER(e.target.value || '0', 'USD'))}
                    style={styles.fieldContainer}
                />
            </Col>
            <Col item>
                <TextField
                    label={'Proveedor'}
                    value={paymentProcessor || ''}
                    onChange={(e) => setPaymentProcessor(e.target.value)}
                    select
                    style={styles.fieldContainer}
                >
                    {
                        [{ key_name: 'binance', name: 'Binance' }, { key_name: 'dfns', name: 'DFNS' }].map((item) => {
                            return (
                                <MenuItem key={item.key_name} value={item.key_name} style={{ justifyContent: 'center' }}>
                                    {item.name}
                                </MenuItem>
                            );
                        })
                    }
                </TextField>
            </Col>
            <Col item>
                <Button
                    onClick={() => saveData()}
                    variant="contained"
                    style={styles.saveButton}
                >
                    Guardar
                </Button>
                {automatic_payment_route_id && (
                    <Button
                        onClick={() => deleteRoute(country_id, automatic_payment_route_id)}
                        variant="contained"
                        style={styles.deleteButton}
                    >
                        Eliminar
                    </Button>
                )}
            </Col>
        </Row>
        {
            isLoading && (
                <Row container style={{ width: '75%', alignContent: 'center' }} >
                    <Col>
                        <LinearProgress color="secondary" />
                    </Col>
                </Row>
            )
        }
    </>
}

AutomaticPaymentCryptoRouteItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    network: PropTypes.object,
    currency: PropTypes.object,
    max_transaction_limit_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payment_processor: PropTypes.string,
    currencies: PropTypes.array,
    networks: PropTypes.array
};

AutomaticPaymentCryptoRouteItem.propTypes = {
    id: 0,
    network: {},
    currency: {},
    max_transaction_limit_amount: 0,
    payment_processor: '',
    currencies: [],
    networks: []
};

export default AutomaticPaymentCryptoRouteItem;
import React from 'react';
import { Filter, SelectInput, TextInput, BooleanInput } from 'react-admin';

import ButtonClearFilters from '../../../Components/ButtonClearFilters';

export const TARGETS = [
    { id: '0', name: 'Usuarios' },
    { id: '1', name: 'Transacciones' },
    { id: '2', name: 'Cuentas Vita Card' },
    { id: '3', name: 'Solicitudes de tarjetas' },
    { id: '4', name: 'Transacciones de tarjetas' },
    { id: '5', name: 'Consulta de convenios' },
    { id: '6', name: 'Consulta de deudas' },
    { id: '7', name: 'Solicitud de pago' },
    { id: '8', name: 'Solicitud de anulación' },
    { id: '9', name: 'Solicitud de firma' },
    { id: '10', name: 'Validación de firma' },
    { id: '11', name: 'Consulta de documentos firmados' },
    { id: '12', name: 'Creación de subcuenta Binance' },
    { id: '13', name: 'Petición a Binance' },
    { id: '14', name: 'Circle cuentas' },
    { id: '15', name: 'Circle solicitudes' },
    { id: '16', name: 'Carga de lote' },
    { id: '17', name: 'Descarga de ACK' },
    { id: '18', name: 'Descarga de REJ ACK' },
    { id: '19', name: 'Descarga de reporte' },
    { id: '20', name: 'Binance pay solicitudes' },
    { id: '21', name: 'Riesgo Cripto' },
    { id: '22', name: 'Solicitud a DFNS' },
    { id: '23', name: 'Solicitud a Truora' },
    { id: '24', name: 'Solicitud a BCI WSDL' },
    { id: '25', name: 'Solicitud a Khipu' },
    { id: '26', name: 'Validar Nro Documento' },
    { id: '27', name: 'Solicitud a Pagacel' },
].sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);

export const PROVIDERS = [
    { id: '0', name: 'Dlocal' },
    { id: '1', name: 'Powwi' },
    { id: '2', name: 'Ripple' },
    { id: '3', name: 'Floid' },
    { id: '4', name: 'Sencillito' },
    { id: '5', name: 'Paytech' },
    { id: '6', name: 'Agile' },
    { id: '7', name: 'Binance' },
    { id: '8', name: 'Circle' },
    { id: '9', name: 'Wyre' },
    { id: '10', name: 'Bancolombia' },
    { id: '11', name: 'Onfido' },
    { id: '12', name: 'Bind' },
    { id: '13', name: 'Binance pay' },
    { id: '14', name: 'Wompi' },
    { id: '15', name: 'CipherTrace' },
    { id: '16', name: 'Nium' },
    { id: '17', name: 'Fortress' },
    { id: '18', name: 'STP' },
    { id: '19', name: 'Coinbase' },
    { id: '20', name: 'Currency Layer' },
    { id: '21', name: 'Dolarsi' },
    { id: '22', name: 'Dolar Today' },
    { id: '23', name: 'YF' },
    { id: '24', name: 'Precios Binance' },
    { id: '25', name: 'DFNS' },
    { id: '26', name: 'Webhooks de business' },
    { id: '27', name: 'Truora' },
    { id: '28', name: 'Vector Capital' },
    { id: '29', name: 'BCI' },
    { id: '30', name: 'SetFX' },
    { id: '31', name: 'Khipu' },
    { id: '32', name: 'Pagacel' },
    { id: '33', name: 'TRM' },
    { id: '34', name: 'Skrill' },
    { id: '35', name: 'Banco Alfin' },
    { id: '36', name: 'Pomelo' },
    { id: '37', name: 'Bridge' },
    { id: '38', name: 'Rendimento' },
    { id: '39', name: 'Transferswap' },
    { id: '40', name: 'Bitso' },
    { id: '41', name: 'B89' },
].sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);

const RequestsFilter = (props) => {
  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
        alwaysOn
        label={"Id de solicitud"}
        source={"id"}
        helperText={"Id de solicitud"}
      />
      <SelectInput
        alwaysOn
        label={"Recurso"}
        source={"target_type"}
        choices={TARGETS}
        style={{ marginBottom: 28 }}
      />
      <SelectInput
        alwaysOn
        label={"Proveedor"}
        source={"provider"}
        choices={PROVIDERS}
        style={{ marginBottom: 28 }}
      />
      <TextInput
        alwaysOn
        label={"Id del recurso"}
        source={"target_id"}
        helperText={"Id del recurso"}
      />
      <BooleanInput
        alwaysOn
        label={"Solicitud fallida?"}
        source={"is_error"}
        helperText={"Solicitud fallida?"}
      />
    </Filter>
  );
};

export default RequestsFilter;
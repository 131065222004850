import React from "react";
import {
  Filter,
  SelectInput,
  DateInput,
} from "react-admin";
import { Grid, withStyles } from "@material-ui/core";

import { CURRENCIES } from "./utils";
import ButtonClearFilters from "../../../../Components/ButtonClearFilters";

const styles = {
  helperText: {
    color: " rgba(255, 255, 255, 0.7)",
    margin: "8px 0",
    fontSize: "0.75rem",
    textAlign: "left",
    lineHeight: "1em",
  },
  dateInput: {
    margin: "8px 0 0 0",
    "&:nth-child(1)": {
      paddingRight: "5px",
    },
    "&:nth-child(2)": {
      paddingLeft: "5px",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: " rgb(140, 140, 140)",
      borderRadius: "5px",
      display: "block",
    },
  },
};

const TransactionFilter = (props) => {
  const { classes, setFilters } = props;

  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={setFilters} alwaysOn/>
      <SelectInput
        label={"Modeda Origen"}
        source={"source_currency"}
        choices={CURRENCIES}
        helperText={"Moneda Origen"}
        alwaysOn
      />
      <SelectInput
        label={"Modeda Destino"}
        source={"destination_currency"}
        choices={CURRENCIES}
        helperText={"Moneda Destino"}
        alwaysOn
      />
      <Grid label="Fecha" source={"transactions_dates"}>
        <Grid container>
          <DateInput
            label={"Desde"}
            source="transactions_dates.from_date"
            className={classes.dateInput}
          />
          <DateInput
            label={"Hasta"}
            source="transactions_dates.to_date"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha de rentabilidades</p>
      </Grid>
    </Filter>
  );
};

export default withStyles(styles)(TransactionFilter);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import useFetchUserLimitRequests from '../../../hooks/useFetchUserLimitRequests';
import UserLimitRequest from '../UserLimitRequest';
import ActiveUserTrxLimit from '../ActiveUserTrxLimit';
import CreateActiveUserTrxLimit from '../CreateActiveUserTrxLimit';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    table: {
      minWidth: 700,
    },
    td: {
        borderBottom: '1px solid #6e6e6e important!',
    },
    formControl: {
        margin: theme.spacing.unit,
        paddingTop: '15px',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    textField: {
        flexBasis: 200,
        paddingTop: '15px',
    },
    containerTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: 1630,
    },
});

const Filters = withStyles(styles)((props = {}) => (
    <div className={props.classes.container}>
        <FormControl className={props.classes.formControl}>
          <InputLabel htmlFor="component-simple">Buscar por ID, documento, email, nombres, apellidos.</InputLabel>
          <Input value={props.email} onChange={props.changeEmail} />
        </FormControl>
        <TextField
          select
          label="Estado"
          className={classNames(props.classes.margin, props.classes.textField)}
          value={props.status}
          onChange={props.changeStatus}
        >
          {props.statuses.map((status) => (
            <MenuItem key={status.id} value={status.id}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
    </div>
));

Filters.propTypes = {
    statuses: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    email: PropTypes.string,
    status: PropTypes.string,
    changeEmail: PropTypes.func,
    changeStatus: PropTypes.func,
};

Filters.defaultProps = {
    statuses: [],
    email: '',
    status: '',
    changeEmail: () => {},
    changeStatus: () => {},
};

const Pagination = (props) => (
    <TablePagination
        rowsPerPageOptions={[5, 25, 50, 250, 500]}
        component="div"
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onChangePage={props.changePage}
        onChangeRowsPerPage={props.changeRowsPerPage}
    />
);

Pagination.propTypes = {
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    total: PropTypes.number,
    changePage: PropTypes.func,
    changeRowsPerPage: PropTypes.func,
};

Pagination.defaultProps = {
    page: 1,
    rowsPerPage: 5,
    total: 0,
    changePage: () => {},
    changeRowsPerPage: () => {},
};

export const UserLimitRequests = withStyles(styles)((props) => (
    <>
        <Paper className={props.classes.root}>
            <Table className={props.classes.table}>
                {
                    props.isDataTable ? (
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Id</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Fecha de solicitud</TableCell>
                                <TableCell align="right">Límite actual</TableCell>
                                <TableCell align="right">Límite solicitado</TableCell>
                                <TableCell align="right">Estado</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                    ) : (
                        <>
                            {props.requests.length > 0 && (
                                <div className={props.classes.containerTitle}>
                                    <div>Solicitudes anteriores</div>
                                    <div>Estado</div>
                                </div>
                            )}
                        </>
                    )
                }
                <TableBody>
                    {props.requests.
                        filter((request) => (props.isDataTable ? true : !request.is_active))
                        .map((request) => (
                            <UserLimitRequest 
                                key={request.id} 
                                {...request}
                                classes={props.classes}
                                updateRequests={props.updateRequests}
                                isDataTable={props.isDataTable}
                            />))
                    }
                </TableBody>
            </Table>
        </Paper>
        {props.requests.length > 0 && <Pagination 
            requests={props.requests}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
            changePage={props.changePage}
            changeRowsPerPage={props.changeRowsPerPage}
            total={props.total}
        />}
    </>
));

UserLimitRequests.propTypes = {
    requests: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            email: PropTypes.string,
            request_date: PropTypes.string,
            current_limit: PropTypes.number,
            requested_limit: PropTypes.number,
            status: PropTypes.string,
        })
    ),
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    total: PropTypes.number,
    changePage: PropTypes.func,
    changeRowsPerPage: PropTypes.func,
    updateRequests: PropTypes.func,
    isFilters: PropTypes.bool,
    isDataTable: PropTypes.bool,
};

UserLimitRequests.defaultProps = {
    requests: [],
    page: 1,
    rowsPerPage: 5,
    total: 0,
    changePage: () => {},
    changeRowsPerPage: () => {},
    updateRequests: () => {},
    isFilters: true,
    isDataTable: false,
};

const UserLimitRequestsHoc = (props) => {
    const hook = useFetchUserLimitRequests(props);
    return (
        <>
            {hook.isFilters && <Filters {...hook.filtersProps} />}
            {hook.isCreateActiveUserTrxLimit && <CreateActiveUserTrxLimit {...hook} />}
            {props.user && <ActiveUserTrxLimit
                updateRequestsParent={hook.updateRequests}
                activeRequest={hook.activeRequest}
                userLimit={hook.userLimit}
            />}
            <UserLimitRequests {...hook} />
        </>
    );
};

UserLimitRequestsHoc.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
    }),
};

UserLimitRequestsHoc.defaultProps = {
    user: null,
};

export default UserLimitRequestsHoc;
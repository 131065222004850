import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const ButtonClearFilters = (props) => {
  const { setFilters, onPress, hasOnPress, isDisable } = props;

  const clearFilters = () => setFilters && setFilters({});

  return (
    <Button
      onClick={hasOnPress ? onPress : clearFilters}
      style={{
        all: "unset",
        cursor: "pointer",
        fontSize: 18,
        width: 20,
        right: 10,
        position: "absolute",
        top: 3,
        color: "#007bff",
        transition: "text-shadow 0.3s ease, color 0.3s ease",
      }}
      onMouseEnter={(e) => {
        e.target.style.textShadow = "0 0 8px rgba(231, 235, 240, 0.8)";
        e.target.style.color = "#0056b3";
      }}
      onMouseLeave={(e) => {
        e.target.style.textShadow = "none";
        e.target.style.color = "#007bff";
      }}
      disabled={isDisable}
      title="Limpiar filtros"
    >
      🗑️
    </Button>
  );
};

export default ButtonClearFilters;

ButtonClearFilters.propTypes = {
  setFilters: PropTypes.func,
  onPress: PropTypes.func,
  hasOnPress: PropTypes.bool,
  isDisable: PropTypes.bool,
};

ButtonClearFilters.defaultProps = {
  setFilters: () => {},
  onPress: () => {},
  hasOnPress: false,
  isDisable: false,
};

import React, { useState, useEffect } from 'react';
import { List, Datagrid } from 'react-admin';
import Swal from "sweetalert2";

import useModal from '../../../../hooks/useModal';
import ModalExport from '../../../../Components/ModalExport';
import useAccessRole from '../../../../hooks/useAccessRole';
import { TransactionFilter } from '../../../Transactions/Filters';
import { Pagination } from '../../../../Components';
import { useReset } from "../../../../hooks";
import TransactionProvider from "../../../../Providers/Transactions";
import { RenderInfo, RenderBalances } from "../../components";
import BinanceProvider from "../../../../Providers/Binance";

const BTC = (props) => {
  const [balances, setBalances] = useState({});
  const { isAccess } = useAccessRole();
  const { open, showModal, hiddeModal } = useModal();

  const getBalancesMasterAdmin = async () => {
    try {
      let balances = await BinanceProvider.masterAdminBalances("btc");
      setBalances(balances.data);
    } catch (error) { }
  };

  useEffect(() => {
    getBalancesMasterAdmin();
  }, []);
  
  const RenderCrypoInfo = (dataGridProps) => {
    const [score, setScore] = useState(null);
    const [hash, setHash] = useState("");
    const [id, setId] = useState("");
    const [isFetch, setFetch] = useState();
    const [isError, setError] = useState(false);
    const [error, setE] = useState("");
    const { resetList } = useReset();

    const automaticWithdrawal = async (id, payment_processor_select = '') => {
      try {
        setFetch(true);
        await TransactionProvider.acceptedWithdawal(id, payment_processor_select);
        resetList();
      } catch (e) {
        setE(e.data.message ? e.data.message : JSON.stringify(e.response.data));
        setError(true);
        setFetch(false);
      }
      setFetch(false);
    };

    const completeWithdrawal = async (id, hash) => {
      try {
        setFetch(true);
        await TransactionProvider.assignHash(id, hash);
        resetList();
      } catch (e) {
        setE(e.data.message ? e.data.message : JSON.stringify(e.response.data));
        setError(true);
        setFetch(false);
      }
      setFetch(false);
    };

    const calculateScore = async (id) => {
      try {
        setFetch(true);
    
        const response = await TransactionProvider.getCryptoRisk(id);
        setScore(response.data);
      } catch(e) {
        Swal.fire(
          'Lo sentimos, algo no fue bien',
          e.data.error.message,
          'error',
        );
        setFetch(false);
      }
      setFetch(false);
    };

    const changeHash = (e) => {
      setHash(e.target.value);
    };

    return <RenderInfo
      {...dataGridProps}
      {...props}
      isFetch={isFetch}
      setFetch={setFetch}
      isError={isError}
      setError={setError}
      error={error}
      setE={setE}
      setId={setId}
      hash={hash}
      score={score}
      changeHash={changeHash}
      automaticWithdrawal={(payment_processor_select) => automaticWithdrawal(id, payment_processor_select)}
      completeWithdrawal={() => completeWithdrawal(id, hash)}
      calculateScore={() => calculateScore(id)}
    />;
  };

  return (
    <>
      <RenderBalances
        balances={balances}
        currencyName={'BTC'}
      />
      <List
        {...props}
        title={'Lista de retiros en Bitcoin'}
        filters={<TransactionFilter isBitcoin labelTabName={props.labelTabName}/>}
        exporter={isAccess('download_btc_withdrawal') ? showModal : false}
        filterDefaultValues={{ isBitcoin: true }}
        pagination={<Pagination />}
        bulkActionButtons={false}
      >
        <Datagrid>
          <RenderCrypoInfo />
        </Datagrid>
      </List>
      <ModalExport
        open={open}
        hiddeModal={hiddeModal}
      />
    </>
  );
};

export default BTC;
import React from 'react';
import { List, Datagrid, TextField, ShowButton, FunctionField, ReferenceField } from 'react-admin';

import useAccessRole from '../../../hooks/useAccessRole';
import { InformationTransaction, ShowEditButton } from '../components';
import { TransactionFilter } from '../Filters';
import { MoneyTransactionField, Pagination, CopyURL } from '../../../Components';
import { RESOLVE_CATEGORY_TRANSACTIONS } from '../../../Helpers/ResourceHelper';
import { AccountBank, AccountBankCustom } from '../../Withdrawals/CustomComponents';
import { flagsCountriesName, WINDOW_FILTER_MOUNTH } from '../../../Config/constants';
import moment from "moment";
import { useAlerts } from '../../../hooks';

const TransactionList = (props) => {
	const { isAccess } = useAccessRole();

	const RenderInfo = (propsTransaction) => {
		const { record, history } = propsTransaction;
		const data = { ...record, history }
		return (
			<InformationTransaction {...data} />
		);
	};

	const AccountBankComponent = (propsList) => {
		const { record } = propsList;
		const {
			id,
			attributes: {
				account_bank,
				sender_user,
				description,
				sender_email,
				is_dlocal,
				category,
				active_alerts,
				alert_count,
				fast_payouts_provider
			}
		} = record;

		const { successMessage } = useAlerts();

		const copyValues = async (value) => {
			await navigator.clipboard.writeText(value);
			successMessage(`Valor copiado ${value}`)
		}

		if (category === "transfer" || category === "withdrawal") {
			if (account_bank)
				return (
					<>
						<div style={{ textAlign: 'center', marginTop: 20 }}> Destinatario</div>
						<AccountBankCustom
							isTransaction
							propsList={propsList}
							{...account_bank}
							sender_user={sender_user}
							transaction_id={id}
							is_dlocal={is_dlocal}
							sender_email={sender_email}
							description={description}
							country={flagsCountriesName[account_bank.iso_code_country]}
							active_alerts={active_alerts}
							alert_count={alert_count}
							copyValues={copyValues}
							fast_payouts_provider={fast_payouts_provider}
							sender={sender_user?.data?.attributes}
						/>
					</>
				);

			return (
				<ReferenceField
					{...propsList}
					label={'Cliente'}
					source={'attributes.sender_id'}
					reference={'users'}
					linkType={false}
				>
					<AccountBank transaction={record} />
				</ReferenceField >
			);
		} else {
			return <div style={{ textAlign: 'center' }}> No aplica</div>
		}
	};

	return (
		<>
			<List
				{...props}
				title={'Lista de transacciones'}
				filters={<TransactionFilter history={props.history} keyFilter={'transactions_dates'}/>}
				exporter={isAccess('download_transactions') ? () => { } : false}
				pagination={<Pagination />}
				bulkActionButtons={false}
				debounce={1000}
				filterDefaultValues={{
					transactions_dates: {
						from_date: moment().subtract(WINDOW_FILTER_MOUNTH, "months").format("YYYY-MM-DD")
					},
				}}
			>
				<Datagrid>
					<TextField
						source={'id'}
						label={'Id'}
					/>
					<TextField
						source={'attributes.alt_id'}
						label={'Id público'}
					/>
					<FunctionField
						label={'Categoria'}
						render={RESOLVE_CATEGORY_TRANSACTIONS}
					/>
					<RenderInfo {...props} />
					<AccountBankComponent {...props} />
					<MoneyTransactionField
						attribute={'total_fee'}
						label={'Comisión'}
						isLabel={false}
					/>
					<MoneyTransactionField
						attribute={'amount'}
						label={'Monto'}
						isLabel={false}
					/>
					<MoneyTransactionField
						attribute={'total'}
						label={'Total'}
						isLabel={false}
					/>
					<CopyURL
						attribute={'encrypt_id'}
						label={'Track URL'}
					/>
					<ShowButton label="" />
					<ShowEditButton {...props} />
				</Datagrid>
			</List>
			{/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
		</>
	);
}

export default TransactionList;

import React, { useState, useEffect } from "react";
import { List, Datagrid } from "react-admin";
import Swal from "sweetalert2";

import { Pagination } from "../../../../Components";
import BinanceProvider from "../../../../Providers/Binance";
import { useReset } from "../../../../hooks";
import { RenderInfo, RenderBalances } from "../../components";
import { TransactionFilter } from '../../../Transactions/Filters';
import TransactionProvider from "../../../../Providers/Transactions";

const USDT = (props) => {
  const [balances, setBalances] = useState({});

  const getBalancesMasterAdmin = async () => {
    try {
      let balances = await BinanceProvider.masterAdminBalances();
      setBalances(balances.data);
    } catch (error) { }
  };

  useEffect(() => {
    getBalancesMasterAdmin();
  }, []);

  const RenderCrypoInfo = (dataGridProps) => {
    const [hash, setHash] = useState("");
    const [id, setId] = useState("");
    const [isFetch, setFetch] = useState();
    const [isError, setError] = useState(false);
    const [error, setE] = useState("");
    const { resetList } = useReset();
    const [score, setScore] = useState(null);

    const automaticWithdrawal = async (id, payment_processor_select = '') => {
      try {
        setFetch(true);
        await TransactionProvider.acceptedWithdawal(id, payment_processor_select);
        resetList();
      } catch (e) {
        setE(e.data.message || JSON.stringify(e.data.data));
        setError(true);
        setFetch(false);
      }
      setFetch(false);
    };

    const completeWithdrawal = async (id, hash) => {
      try {
        setFetch(true);
        await BinanceProvider.completeBinanceWithdrawal({ id, hash });
        resetList();
      } catch (e) {
        setE(e.data.data);
        setError(true);
        setFetch(false);
      }
      setFetch(false);
    };

    const rejectWithdrawal = async (id) => {
      try {
        setFetch(true);
        await BinanceProvider.rejectBinanceWithdrawal({ id });
        resetList();
      } catch (e) {
        setE(e.data.message || JSON.stringify(e.data.data));
        setError(true);
        setFetch(false);
      }
      setFetch(false);
    };

    const calculateScore = async (id) => {
      try {
        setFetch(true);
    
        const response = await TransactionProvider.getCryptoRisk(id);
        setScore(response.data);
      } catch(e) {
        Swal.fire(
          'Lo sentimos, algo no fue bien',
          e.data.error.message,
          'error',
        );
        setFetch(false);
      }
      setFetch(false);
    };

    const changeHash = (e) => {
      setHash(e.target.value);
    };

    return <RenderInfo
      {...dataGridProps}
      {...props}
      isFetch={isFetch}
      setFetch={setFetch}
      isError={isError}
      setError={setError}
      error={error}
      setE={setE}
      setId={setId}
      hash={hash}
      score={score}
      changeHash={changeHash}
      automaticWithdrawal={(payment_processor_select) => automaticWithdrawal(id, payment_processor_select)}
      completeWithdrawal={() => completeWithdrawal(id, hash)}
      rejectWithdrawal={() => rejectWithdrawal(id)}
      calculateScore={() => calculateScore(id)}
    />;
  };

  return (
    <>
      <RenderBalances
        balances={balances}
        currencyName="USDT"
      />
      <List
        {...props}
        title={"Lista de retiros Binance"}
        filters={<TransactionFilter isBitcoin labelTabName={props.labelTabName}/>}
        pagination={<Pagination />}
        filterDefaultValues={{ isBinance: true }}
        exporter={false}
        bulkActionButtons={false}
      >
        <Datagrid>
          <RenderCrypoInfo />
        </Datagrid>
      </List>
    </>
  );
};

export default USDT;

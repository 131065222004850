import React from "react";
import { Datagrid, List, TextField, ShowButton } from "react-admin";
import { CouponFilter } from "../Filters";
import Swal from "sweetalert2";

import FormControl from "./Components/FormControl";
import HeaderMenu from "./Components/HeaderMenu";
import Rest from "../../../Providers/Rest";
import useAlerts from "../../../hooks/useAlerts";

const CouponList = (props) => {
  const { history } = props;
  const { errorMessage } = useAlerts();

  const StatusSwich = (props) => {
    const {
      record: { id, attributes },
    } = props;

    if (attributes.status === "active") {
      return (<FormControl
        status={true}
        id={id}
        disabled={false}
        isConfirm={true}
        message={'El cupón ha sido inactivado con éxito.'}
        {...props}
      />);
    }

    if (attributes.status === "pending" || attributes.status === "inactive")
      return <FormControl status={false} id={id} disabled={false} {...props} />;

    return <FormControl status={false} id={id} disabled={true} {...props} />;
  };
  const send = async (e) => {
    try {
      await Rest("DELETE", "coupons", e);
      history.push("/coupons");
    } catch (e) {
      errorMessage(e.data.error.message)
    }
  };

  const deleteCoupon = (e, status) => {
    Swal.fire({
      title: "Estas seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        send(e);
        Swal.fire("Eliminado!", "El cupón ha sido eliminado.");
      }
    });
  };

  let ButtonAction = (props) => {
    const {
      record: {
        id,
        attributes: { status },
      },
    } = props;

    return (
      <>
        <button
          name={id}
          style={{
            backgroundColor:
              status === "finalized" || status === "active"
                ? "gray"
                : "#3fb7fe",
            padding: 8,
            borderRadius: 10,
            borderColor: "none",
          }}
          disabled={status === "finalized" || status === "active"}
          type="button"
          onClick={(e) => {
            props.name === "Eliminar"
              ? deleteCoupon(id)
              : history.push(`/coupons/${id}`);
          }}
        >
          <div style={{ color: "#ffffff" }}>{props.name}</div>
        </button>
      </>
    );
  };

  return (
    <>
      <HeaderMenu history={history} />
      <List
        {...props}
        title={"Cupones"}
        filters={<CouponFilter />}
        bulkActionButtons={false}
        exporter={() => { }}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"attributes.identifier"} label={"Identificador"} />
          <TextField source={"attributes.campaign"} label={"Campaña"} />
          <TextField source={"attributes.start_date"} label={"F. Inicio"} />
          <TextField source={"attributes.end_date"} label={"F. Fin"} />
          <TextField
            source={"attributes.number_coupons"}
            label={"Cantidad de cupones"}
          />
          <TextField
            source={"attributes.bonus_type"}
            label={"Tipo de bonificación"}
          />
          <TextField
            source={"attributes.min_amount_transaction"}
            label={"Monto min de transaccióon"}
          />
          <TextField
            source={"attributes.status"}
            label={"Estado"}
            style={{ width: 50 }}
          />

          <StatusSwich label={"Publicar"} />

          <ButtonAction name={"Editar"} />
          <ShowButton label='' />
          <ButtonAction name={"Eliminar"} />
        </Datagrid>
      </List>
    </>
  );
};

export default CouponList;

import React, { useState, useEffect } from 'react';
import { TransferRulesProvider, FavoriteAccountsProvider, CountriesProvider } from '../../../../../../../Providers';
import { TextField, MenuItem, Grid, Button } from '@material-ui/core';
import ModalConfirm from '../../../../../../../Components/ModalConfirm';
import useAlerts from '../../../../../../../hooks/useAlerts';
import useReset from '../../../../../../../hooks/useReset';

const accountTypeFilters = [
  "account_bank__code_cpf",
  "account_bank__code_cnpj",
  "account_bank__phone_number",
  "account_bank__email",
  "account_bank__random_key",
];

const CreateOrEditFavoriteAccount = (props) => {
  const { editFavorite, account_type, action, record } = props;
  const { resetList } = useReset();
  const {confirmationModal, errorMessage} = useAlerts();

  let editFavoriteEmpty = null;

  if (Object.keys(editFavorite).length === 0) {
    editFavoriteEmpty = {
      document_type: '',
      document_type_name: '',
      document_type_select: '',
      account_type_bank_select: '',
      bank_id: '',
      bank_name: '',
      bank_id_select: '',
      beneficiary_type: '',
      beneficiary_type_select: ''
    };
  }

  const [ rules, setRules ] = useState([]);
  const [ rule, setRule ] = useState([]);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModalError, setOpenModalError ] = useState(false);
  const [ messageError, setMessageError ] = useState('');
  const [ country, setCountry ] = useState('');
  const [ countries, setCountries ] = useState([]);
  const [ favoriteAccount, setFavoriteAccount ] = useState(editFavoriteEmpty === null ? editFavorite : editFavoriteEmpty);

  const getTransferRules = async () => {
    try {
      const resp = await TransferRulesProvider.getTransferRules();
      setRules(resp.data);

      if (action !== 'create'){
        setRule(resp.data[editFavorite.destination_iso_code].rules);
      }
    } catch (e) { }
  }

  const getCountries = async () => {
    try {
      const filter = 'q[is_transfer_eq]=true';
      const resp = await CountriesProvider.fetchFilterCountriesAdmin(filter);
      setCountries(resp.data.countries.data);
    } catch (e) { }
  }

  useEffect(() => {
    getTransferRules();
  }, []);

  useEffect(() => {
    if (action === 'create'){
      getCountries();
    }
  }, []);

  const changeCountry = e => {
    const countrySelected = countries.find(c => c.attributes.iso_code.toLowerCase() === e.target.value.toLowerCase());

    setRule(rules[e.target.value.toLowerCase()].rules);
    setFavoriteAccount({
      ...favoriteAccount,
      destination_iso_code: e.target.value.toLowerCase()
    });
    setCountry(countrySelected.attributes.iso_code);
  }

  const changeValue = (e, nameKey, field) => {
    let name = e.target.name;
    const value = e.target.value;
    const isoCode = favoriteAccount.destination_iso_code;

    if (accountTypeFilters.includes(name)){
      name = 'account_bank';
    };

    let attr = {
      ...favoriteAccount,
      [name]: value
    }

    if (nameKey !== null && field) {
      const label = field.options.find( option => {
        if (value === option.label)
          return option.label;
      }).label;

      const select_value = field.options.find(opt => value === opt.label).value;
      const select_label = field.options.find(opt => value === opt.label).label;

      if (nameKey === 'document_type') {
        attr[`${nameKey}_name`] = label;
        attr[nameKey] = select_value;
      } else if (nameKey === 'bank_id') {
        attr['bank_name'] = label;
        attr['bank_id'] = select_value;
      } else if (nameKey === 'account_type_bank') {
        attr['account_type_bank'] = select_value;
      } else if (nameKey === 'beneficiary_type') {
        if (isoCode !== 'us' && isoCode !== 'gb') {
          attr[nameKey] = value;
        }
      } else if (nameKey === 'pix_key_type') {
        attr['account_type_bank'] = select_value;
        attr['account_type_bank_select'] = select_label;
      } else {
        attr[nameKey] = select_value;
      }
    }

    setFavoriteAccount(attr);
  }

  const validateTypeField = field => (
    field.type === 'text' || field.type === 'email' || field.type === 'numeric'
  )

  const validateShowField = field => {
    let visible = true;
    if (field.hasOwnProperty('visible')) {
      // Brasil and in rule === 'Datos bancarios'
      if(field.visible.value === favoriteAccount['account_type_bank']){
        return visible;
      }

      switch (field.visible.key) {
        case 'beneficiary_type':
          visible = favoriteAccount['beneficiary_type_select'] === field.visible.value;
        break;

        default:
          visible = favoriteAccount[field.visible.key] === field.visible.value;;
        break;
      }
    }

    return field.isFrequentAccountField && visible;
  }

  const validatForm = () => {
    let data = favoriteAccount;
    const isoCode = data.destination_iso_code;

    if (isoCode !== 'us' && isoCode !== 'gb') {
      data.bank_account_type = isoCode === 'mx' ? 'S' : data.account_type_bank_select;
      data.bank_account_type_name = isoCode === 'mx' ? 'Cuenta de ahorros' : data.account_type_bank;
      data.account_number = data.account_bank;
      data.beneficiary_type_select = data.beneficiary_type;
    } else {
      data.account_type_bank = data.account_type_bank_select;
      data.bank_account_type = data.account_type_bank;
      data.account_type_bank_select = data.account_type_bank === 'Savings' ? 'Cuenta de ahorro' : 'Cuenta corriente';
      data.document_type_select = data.document_type_name;
      data.beneficiary_type = data.beneficiary_type_select;
      data.beneficiary_type_select = data.beneficiary_type_select === 'Individual' ? data.beneficiary_type_select : 'Corporativo';
      data.first_name = data.first_name ? data.first_name : '';
      data.last_name = data.first_name ? data.last_name : '';
      data.company_name = data.company_name ? data.company_name : '';
      data.account_number = '-1';
      data.bank_id = '-1';
      data.bank_name = '-1';
    }

    return data;
  }

  const handleMessageError = (e, type) => {
    if(e?.data?.error){
      const mappedErrors = {};

      for (const [field, messages] of Object.entries(e.data.error)) {
          mappedErrors[field] = messages.join(", ");
      }

      let errors = '';

      Object.entries(mappedErrors).map( error => errors += `- ${error}\n`);

      return errorMessage(errors);
    }

    errorMessage(`ERROR AL ${type} FAVORITO`);
  }

  const createAccount = async e => {
    e.preventDefault();
    const data = validatForm();
    data.user_id = record.id;
    data.account_type = account_type;
    
    try {
      const resp = await FavoriteAccountsProvider.createAccount(data);
      if( resp.data.hasOwnProperty('error') ) {
        setOpenModalError(true);
        setMessageError(`${Object.keys(resp.data.error)[0]}: ${resp.data.error[Object.keys(resp.data.error)[0]]}`);
      } else {
        await confirmationModal({text: "Favorito creado correctamente.", background: "white" });
        resetList();
      }
    } catch (e) {
      handleMessageError(e, 'CREAR');
    }
  }

  const updateAccount = async e => {
    e.preventDefault();
    const data = validatForm();

    try {
      const resp = await FavoriteAccountsProvider.updateAccount(favoriteAccount.id, data);
      if( resp.data.hasOwnProperty('error') ) {
        setOpenModalError(true);
        setMessageError(`${Object.keys(resp.data.error)[0]}: ${resp.data.error[Object.keys(resp.data.error)[0]]}`);
      } else {
        await confirmationModal({text: "Favorito actualizado correctamente.", background: "white" });
        resetList();
      }
    } catch (e) {
      handleMessageError(e, 'ACTUALIZAR');
    }
  }

  const deleteAccount = async e => {
    try {
      await FavoriteAccountsProvider.deleteAccount(favoriteAccount.id);
      await confirmationModal({text: 'Favorito eliminado correctamente.', background: "white"});

      setOpenModal(false)
      resetList();
    } catch (e) {
      handleMessageError(e, 'ELIMINAR');
    }
  }

  const handleFindValue = (field) => {
    switch (true) {
      case field.name === 'pix_key_type_select':
        return favoriteAccount.account_type_bank_select;
      case accountTypeFilters.includes(field.name):
        return favoriteAccount.account_bank;
      default:
        return favoriteAccount[field.name];
    };
  };

  return (
    <form onSubmit={ (e) => { action === 'create' ? createAccount(e) : updateAccount(e)} }>
      <div>
        {(action === 'create' && account_type === 'international' &&
        <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}> 
          <TextField
            id='country'
            name='country'
            select
            label='País'
            required
            fullWidth
            margin="normal"
            value={country}
            onChange={(e) => changeCountry(e)}
          >
            {countries.map(option => (
              <MenuItem
                key={option.attributes.iso_code}
                value={option.attributes.iso_code}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>)}

        {account_type === 'international' ?
          rule.map(rule => (
            (rule.isFrequentAccountField ?
              <Grid key={rule.label} container>
                <Grid item xs={12}><h3>{rule.label}</h3></Grid>
              
                {rule.fields.map(field => (
                  (validateShowField(field) ?
                    (validateTypeField(field) ?
                      <Grid key={field.name} item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
                        <TextField
                          id={field.name}
                          name={field.name}
                          label={field.label}
                          type={
                            field.type === "email" ? "email" :
                            field.type === 'numeric' ? 'number' : 'text'
                          }
                          value={handleFindValue(field)}
                          required
                          fullWidth
                          margin="normal"
                          inputProps={{
                            maxLength: field.max ? field.max : '',
                            minLength: field.min ? field.min : ''
                          }}
                          onChange={(e) => changeValue(e, null)}
                        />
                      </Grid>
                    : <Grid key={field.name} item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
                        <TextField
                          id={field.name}
                          name={field.name}
                          select
                          label={field.label}
                          value={handleFindValue(field)}
                          required={true}
                          fullWidth
                          margin="normal"
                          onChange={(e) => changeValue(e, field.name_key, field)}
                        >
                          {field.options.map(option => (
                            <MenuItem
                              key={option.value}
                              value={option.label}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>)
                  : '')
                ))}
              </Grid>
            : '')
          ))
        : <Grid container>
            <Grid item xs={12}><h3>Datos</h3></Grid>

            <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
              <TextField
                id="first_name"
                name="first_name"
                label="Nombres"
                type="text"
                value={favoriteAccount["first_name"]}
                required
                fullWidth
                margin="normal"
                onChange={(e) => changeValue(e, null)}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
              <TextField
                id="last_name"
                name="last_name"
                label="Apellidos"
                type="text"
                value={favoriteAccount["last_name"]}
                required
                fullWidth
                margin="normal"
                onChange={(e) => changeValue(e, null)}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
              <TextField
                id="email"
                name="email"
                label="Correo"
                type="email"
                value={favoriteAccount["email"]}
                required
                fullWidth
                margin="normal"
                onChange={(e) => changeValue(e, null)}
              />
            </Grid>
          </Grid>
        }
        <Grid container>
          <Grid item xs={12}><h3>Descripción</h3></Grid>
          <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
            <TextField
              id="account_alias"
              name="account_alias"
              label="Alias"
              type="text"
              value={favoriteAccount["account_alias"]}
              required
              fullWidth
              margin="normal"
              onChange={(e) => changeValue(e, null)}
              inputProps={{ maxLength: 14 }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button 
              variant="contained"
              color="primary"
              type="submit"
              style={{marginRight: 10}}
            >
              {action === 'create' ? 'Guardar' : 'Actualizar'}
            </Button>

            {action === 'edit' && <Button 
              variant="contained"
              color="primary"
              onClick={ (e) => { setOpenModal(true) } }>
              Eliminar
            </Button>}
          </Grid>
        </Grid>

        <ModalConfirm
          open={openModal}
          setOpen={setOpenModal}
          message='¿Está seguro de que desea eliminar este favorito? Esta acción no se puede deshacer.'
          action={deleteAccount}
        />

        <ModalConfirm
          open={openModalError}
          setOpen={setOpenModalError}
          message={messageError}
        />
      </div>
    </form>
  );
}
 
export default CreateOrEditFavoriteAccount;

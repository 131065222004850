import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const getCurrencies = async () => 
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CURRENCIES.GET_CURRENCIES,
  });

const getCurrenciesByIsoCodes = async (isoCodes) => {
  const queryParams = isoCodes.map((item)=>`q[iso_code_in][]=${item}`).join('&');
  const url = `${ENDPOINTS.ADMIN.CURRENCIES.GET_CURRENCIES.url}?${queryParams}`

  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CURRENCIES.GET_CURRENCIES,
    url
  });
}

export default {
  getCurrencies,
  getCurrenciesByIsoCodes,
};